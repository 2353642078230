@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height;
}

.spacerBlockBottom {
  padding-bottom: $verticleBlockPaddingXS;
}

.spacerBlockTop {
  padding-top: 24px;
}

.intro {
  font-weight: 500;
}

.topContent {
  width: 100%;
  padding-top: 40px;
  &:empty {
    padding-top: 0;
  }
}

.btnContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  text-shadow: none;
}

.categoryTitle {
  @include HelveticaMedium;
  font-size: 10px;
  line-height: 12px;
  color: $dark-gray;
  width: 100%;
  padding-bottom: 16px;
  text-transform: uppercase;
  &.spacingTop {
    padding-top: 24px
  }
}

.categoryContainer {
  margin-bottom: 24px;
  overflow-x: auto;
  /* width */
  &::-webkit-scrollbar {
    display: none;
  }
}

.categoryWrapper {
  display: flex;
  width: max-content;
}

@media #{$sm-up} {
  .topContent {
    max-width: 770px;
    margin: 0 auto;
  }
}

@media #{$md-up} {
  .root {
    padding-top: $standard-header-height-md-up;
  }

  .topContent {
    padding-top: 60px;
    &:empty {
      padding-top: 0;
    }
  }

  .spacerBlockBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .spacerBlockTop {
    padding-top: 56px;
  }
  .categoryTitle {
  &.spacingTop {
    padding-top: 56px
    }
  }
}

@media #{$lg-up} {
  .category {
    overflow-x: auto;
    padding-right: 16px;
  }

  .categoryWrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    width: unset;
  }
}